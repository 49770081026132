import $ from 'jquery';
import menubanner from './libs/menubanner.js'
import responsiveImage from './libs/responsive-images'

window.$ = $

menubanner($).dropmenu()

const app = {};


app.menumobile = function () {
    $('.right-header > nav > ul').on('click', '> li.have-child > a', function (e) {
        e.preventDefault();
        if (!$(this).parent().hasClass('drop')) {
            $('.right-header > nav > ul > li.have-child > a').not($(this)).parent().removeClass('drop').find('> ul').slideUp().find('.have-child').removeClass('drop').find('> ul').hide()
            $(this).parent().addClass('drop')
            $(this).parent().find('> ul').slideDown()
        } else {
            $(this).parent().removeClass('drop')
            $(this).next().stop().slideUp()
        }
    })
    $(document).bind('click', function (e) {
        var $clicked = $(e.target);
        if (!$clicked.parents().hasClass("drop")) {
            $('.right-header nav >ul > li.have-child a').parent().removeClass('drop')
            $('.right-header nav >ul > li.have-child > a').next().stop().slideUp()
            $('.right-header nav >ul > li.have-child .have-child > a').parent().removeClass('drop').find('> ul').hide()
        }
    });
    if ($(window).width() <= 1024) {
        $('.togglemenu').click(function () {
            if ($(this).hasClass('close')) {
                $('.right-header').stop().fadeOut();
                $(this).removeClass('close');
                $('body').removeClass('hidden');

            } else {
                $('.right-header').stop().fadeIn();
                $(this).addClass('close');
                $('body').addClass('hidden');
            }
        });
    }
    $('.right-header nav ul').on('click', '> li.have-child .have-child > a', function (e) {
        e.preventDefault();
        if (!$(this).parent().hasClass('drop')) {
            $('.right-header > nav > ul > li.have-child .have-child > a').not($(this)).parent().removeClass('drop').find('> ul').hide()
            $(this).parent().addClass('drop')
            $(this).next().show()
        } else {
            $(this).parent().removeClass('drop')
            $(this).next().hide()
        }
    })
}
app.tableMiddle = function () {
    $(".wrap-table").each(function () {
        var table = $(this).find('table'),
            index = 0;

        table.find("thead th").each(function () {
            $(this).attr('data-title', $(this).text())
            index = $(this).index() + 1;

            table.find("td:nth-child(" + index + ")").attr('data-title', $(this).text())
        })

    })
}
app.toTop = function () {
    $('.backToTop').on('click', function () {
        $('html,body').animate({ scrollTop: 0 }, 1000);
    })

    const float = function () {
        if ($(window).scrollTop() > 100) {
            $('.backToTop').addClass('floating');
            if ($(window).scrollTop() + $(window).height() >= $(document).height() - ($('footer').height())) {
                $('.backToTop').addClass('no-float');
            } else {
                $('.backToTop').removeClass('no-float');
            }
        } else {
            $('.backToTop').removeClass('floating');
        }
    }
    float();
    $(window).scroll(function () {
        float();
    });

}

app.init = function () {
    app.menumobile()
    app.tableMiddle()
}

app.init()

$(document).ready(() => {
    app.toTop()
});

window.addEventListener('load', (event) => {
  $('body .de-paging-section').show();
  responsiveImage($).init($(".responsive-images figure img, .milestone figure img, .slider-box .list img"), {
    type: 'image' //type image = <img>  or bg = background: url(...)
  })
});