export default ($) => ({
	dropmenu() {
		$('.navigasi-drop .select >a').on('click', function (e) {
			e.preventDefault()
			$(this).next('.drop').slideToggle()
			$(this).parent().toggleClass('active-drop')
		})

		$(document).bind('click', function (e) {
			var $clicked = $(e.target);
			if (!$clicked.parents().hasClass("navigasi-drop")) {
				$('.navigasi-drop .select >a').next('.drop').slideUp()
				$('.navigasi-drop .select >a').parent().removeClass('active-drop')
			}
		});
	}
})